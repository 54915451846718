<template>
    <admin-dashboard-layout :loading="loading">
        <v-container>
            <v-form>
                <v-card class="mx-15 px-15">
                    <v-card-title>
                        <v-row>
                            <v-col v-text="infographic.name"/>
                            <v-col class="text-right">
                                <v-btn text :to="{name: 'admin.infographics.index'}">return</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-select
                                v-model="infographic.language"
                                :items="languages"
                                item-text="label"
                                item-value="code"
                                outlined
                                dense
                                label="Language">
                                <template slot="selection" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                            </v-select>
                            <v-text-field outlined dense label="Name" v-model="infographic.name"/>
                            <v-row>
                                <v-col md="4" sm="12">
                                    <v-img :src="infographicAvatar" />
                                </v-col>
                                <v-col md="8" sm="12">
                                    <v-textarea outlined v-model="infographic.description" label="Description"/>
                                    <v-textarea outlined v-model="infographic.notes" label="Notes"/>
                                    <AdminUploadFiles v-model="infographic.files" name="Infographic" :file="infographic.files" directory="infographics"/>
                                    <v-text-field label="Rank" v-model="infographic.rank" outlined dense/>
                                    <v-select
                                        v-model="infographic.tags"
                                        outlined
                                        dense
                                        label="Tags"
                                        item-text="name"
                                        item-value="id"
                                        :multiple="true"
                                        :items="tags"/>
                                    <v-select
                                        v-model="infographic.industry_id"
                                        outlined
                                        dense
                                        label="Industries"
                                        item-text="name"
                                        item-value="id"
                                        :items="industries"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn @click="handleSave">  Save </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>
    import AdminUploadFiles from "@/components/admin/AdminUploadFiles";
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import languages from "@/configs/languages";
    import Infographic from "@/models/Infographic";
    import Tag from "@/models/Tag";
    import Industry from "@/models/Industry";
    import {mapMutations} from "vuex";

    export default {
        name: "Form",
        components: {
            AdminUploadFiles,
            AdminDashboardLayout,
        },
        computed: {
            infographicAvatar() {
                return this.infographic.files
            }
        },
        data: function () {
            return {
                loading: false,
                languages: languages,
                carousel: 0,
                infographic: {
                    files: ''
                },
                tags: [],
                industries: []
            }
        },
        async mounted() {
            this.loading = true;
            const infographicId = this.$route.params.id

            await this.getIndustries();
            this.tags = await Tag.get();

            if (infographicId) {
                this.infographic = await Infographic.include(['tags']).find(infographicId)
            } else {
                let nextRank = await window.axios.get('/api/helpers/next-rank/infographics');
                this.infographic.rank = nextRank.data
                this.$forceUpdate()
            }





            this.loading = false;
        },
        methods: {
            async handleSave() {
                if (this.$_.isArray(this.infographic.tags)) {
                    this.infographic.tags = this.infographic.tags.map((tag) => this.$_.isObject(tag) ? tag.id : tag);
                }

                const infographic = new Infographic(this.infographic)
                this.infographic = await infographic.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'});
                if (this.infographic.id && ! this.$route.params.id) {
                    await this.$router.push({name: 'admin.infographics.show', params: {id: this.infographic.id}})
                }
            },

            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.infographic.industry_id) {
                    this.infographic.industry_id = this.industries[0].id
                }
            },
            ...mapMutations(['showSnackBar']),
        }
    }
</script>
